.license-selection-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 600px; /* Set the height you need */
  overflow-y: auto;  /* Enable vertical scrolling */
  padding: 10px;
}

.group-header {
  font-size: 18px;
  font-weight: bold;
}

.options-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option-label {
  font-size: 16px;
}

.with-header {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
}

.without-header {
  padding: 10px; /* No border, just padding */
}
