/* .music-list {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
  } */

.music-list h2 {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #000;
  color: #fff;
}

th,
td {
  padding: 15px;
  text-align: left;
}

th {
  font-weight: bold;
}

tbody tr {
  border-bottom: 1px solid #ddd;
}

.music-item {
  display: flex;
  align-items: center;
  position: relative;
}
.purchase_table .music-item img.video_sec {
  position: absolute;
  left: 37px;
}
.music-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 8px;
}

.music-title {
  margin: 0;
  font-weight: bold;
}

.music-artist {
  margin: 0;
  color: #777;
}

.like-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.buy-button {
  background: #ffcc00;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

.buy-button:hover {
  background: #e6b800;
}

.video-player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* .main_pageWidth .video-player {
      background: transparent;
      width: 844.52px;
      padding: 0px;
      position: relative;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 476px;
      border-radius: 0px !important;
  } */

.video-player {
  background: transparent;
  width: 60%;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_pageWidth .video-player button.close-button {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.video-element {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.music-image {
  width: 100%;
  max-width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 8px;
  object-fit: contain;  
}
@media (max-width:767px){
  .filter_icon {
    border: 1px solid;
    width: 24px;
    height: 24px;
    border-radius: 30px;
}
.music-image {
  max-width: 40px;
  height: 40px;
  border-radius: 4px;
}
.purchase_table .music-item img.video_sec {
  position: absolute;
  left: 14px;
  width: 12px;
}
table.purchase_table thead.thheader {
  display: none;
}
.filter_icon img {
    position: relative;
    top: -3px;
}
}