.header_alltab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
}

.header_alltab h1 {
  font-size: 1.5em;
  margin: 0;
  font-family: poppins;
}

.explore-link {
  font-size: 1em;
  text-decoration: none;
  color: black;
  font-family: poppins;

}

@media (max-width:767px){
  .header_alltab h1 {
    font-size: 18px;
    line-height: 27px;
    font-family: Poppins;
}
.filter_icon {
  border: 1px solid;
  width: 24px;
  height: 24px;
  border-radius: 30px;
}

.filter_icon img {
  position: relative;
  top: -3px;
}
  .explore-link {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}
}


.audio-player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio-player-container {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Set a maximum width */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px; /* Increase font size for better visibility */
}

.audio-player {
  margin-top: 40px; /* Ensure enough space for the button */
}

.audio-element {
  width: 100%; /* Make sure the audio player takes full width of its container */
  height: 50px; /* Set a fixed height to ensure it's large enough */
}
