.music-card {
  width: 300px;
  height: 180px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
}

.music-card-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0 14px 14px 14px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.8) 80%);
  position: absolute;
  bottom: 0;
}

.music-card-text h3 {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.music-card-text p {
  color: white;
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
}

.music-card-text img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

/* Play button styling */
.play-button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

/* Remove background or focus styling on hover or focus */
.play-button:focus,
.play-button:hover,
.play-button:active {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.play-button img {
  width: 29px;
  height: 29px;
}

@media (max-width: 768px) {
  .music-card{
    height: 160px;
    width: 165px;
  }
  .play-button{
    display: none;
  }
  .music-card-text h3 {
    font-size: 12px;
  }
  .music-card-text p {
    font-size: 12px;
  }
}