
.tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
  }
  
  .tabs {
    display: flex;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tab:hover {
    background-color: #f0f0f0;
  }
  
  .search-bar {
    padding: 10px;
    font-size: 16px;
    width: 300px;
  }
  .filter {
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 800px; /* Adjust the height as needed */
    background: #fff;
  }
  
  .filter-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .filter-category {
    margin-bottom: 20px;
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-header h4 {
    margin: 0;
    font-size: 18px;
    font-weight: normal;
  }
  
  .filter-toggle {
    font-size: 18px;
    cursor: pointer;
  }
  
  .filter-option {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .filter-option input {
    margin-right: 10px;
  }
  
  hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 20px 0;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .content-category {
    margin-bottom: 40px;
  }
  
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .content-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .content-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .content-details {
    padding: 10px;
  }
  
  .play-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .play-button:hover {
    background-color: #0056b3;
  }
      