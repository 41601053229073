/* Music Section Styles */
.section {
  padding: 20px;
  margin-bottom: 40px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h2 {
  font-size: 24px;
  font-weight: bold;
}

.explore-link {
  font-size: 14px;
  color: #D8A136;
  text-decoration: none;
}

.explore-link:hover {
  text-decoration: underline;
}

/* Grid Layout for Music Cards */
.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for desktop */
  gap: 20px; /* Space between cards */
}

@media (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for tablet view */
  }
}

@media (max-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for tablet view */
  }
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr); /* 1 column for mobile view */
  }
}
