.input-container {
  position: relative;
}

.input-field {
  display: block;
  width: 100%;
  padding: 0.75rem 0.625rem;
  color: #1f2937;
  background-color: transparent;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  outline: none;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.input-field:focus {
  border-width: 2px;
  border-color: #3b82f6;
}

.input-field:disabled {
  cursor: not-allowed;
  color: #64748b;
  border-color: #cbd5e1;
}

.input-field-disabled:focus {
  border-color: #cbd5e1;
}

.input-error {
  color: #ef4444;
  border-color: #ef4444;
}

.input-error:focus {
  border-color: #ef4444;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #1f2937;
}

.suffix-icon {
  position: absolute;
  right: 0;
  padding-right: 0.75rem;
  transform: translateX(0) translateY(-50%);
  top: 45%;
}

.suffix-icon-disabled {
  pointer-events: none;
}

.error-text {
  color: #ef4444;
  font-size: 0.875rem;
  display: flex;
  text-align: left;
  margin-top: 0.5rem;
}
