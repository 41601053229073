/* Layout for the entire admin panel */
.admin-layout {
  display: flex;
  height: 100vh; /* Full height of the viewport */
  width: 100%;
}

/* Sidebar styles */
.sidebar {
  width: 280px;
  background-color: #1C1C1E;
  color: white;
  height: 100vh; /* Full height of the viewport */
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  transition: transform 0.3s ease;
  z-index: 999;
}

.sidebar.closed {
  transform: translateX(-100%); /* Hide sidebar when closed */
}

.sidebar.open {
  transform: translateX(0); /* Show sidebar when open */
}

/* Sidebar option styles */
.sidebar-option {
  margin-bottom: 20px;
}

/* Sidebar links */
.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin: 4px 12px;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.sidebar-link:hover {
  background-color: #D8A136;
  color: #111;
}

.sidebar-link.active {
  background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
  color: #111;
}

/* Sidebar icons */
.sidebar-icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

/* Sidebar text */
.sidebar-text {
  font-size: 16px;
  font-weight: 400;
}

/* Content area styles */
.content {
  margin-left: 280px; /* This aligns content next to sidebar when it's open */
  padding: 90px 20px 20px 20px;
  height: 100vh; /* Ensure the content fills the remaining height */
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  width: calc(100% - 280px); /* Adjust content width to account for sidebar width */
  background-color: #FAFAFA;
  text-align: left;
}

/* Adjust content when the sidebar is closed */
.sidebar.closed ~ .content {
  margin-left: 0; /* Remove the margin when the sidebar is closed */
  width: 100%; /* Content takes up the full width when sidebar is hidden */
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    top: 40px;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .content {
    margin-left: 0; /* Remove the margin for smaller screens */
    width: 100%; /* Full-width content on smaller screens */
    padding: 52px 12px 12px 12px; /* Adjust padding for smaller screens */
  }
}
