.user-component {
  /* display: none; */
}
.header_Sec {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1280px;
  padding: 15px;
}
.header_Sec {
  background-color: #3330 !important;
}
.header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.Header_btns {
  display: flex;
  gap: 50px;
  justify-content: end;
  flex: 1;
}

.Header_btns button {
  font-size: 17px;
  line-height: 25.5px;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  border-radius: 10px;
  border: 2px solid #ffffff;
  transition: 0.5s all;
  cursor: pointer;
}

.Header_btns button:first-child {
  color: #ffffff;
  padding: 10px 15px;
  min-height: 52px;
  background-color: transparent;
}

.Header_btns button:first-child:hover {
  color: #181717;
  background: #ffffff;
}

.Header_btns button:last-child {
  color: #181717;
  background: #ffffff;
  width: 100%;
  max-width: 127px;
  min-height: 52px;
  padding: 10px 15px;
}

.Header_btns button:last-child:hover {
  color: #ffffff;
  background: transparent;
}

.profile-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.side-menu-header .close-button {
  position: absolute;
  top: -20px;
  right: -10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
  z-index: 1001;
}

.side-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 584px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 30px;
  z-index: 1000;
}

.side-menu-header {
  position: relative;
  z-index: 1001;
}

.side-menu-content span.searchbycodestep {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #707070;
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.side-menu-content h4 {
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  margin-bottom: 30px;
}
/* .side-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
form.Progress_form .check_progress:hover {
  border-color: #111;
}
form.Progress_form .check_progress {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.close-button {
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.side-menu-content h3 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  margin: 0px;
  color: #181717;
}

.side-menu-content ul {
  list-style: none;
  padding: 0;
}
.side-menu-content {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.side-menu-content ul li {
  margin: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--Color-Black-10, #dfdfdf);
  padding: 20px 0px 20px;
}

.side-menu-content .logout-button {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50px;
  min-height: 70px;
  border: 1px solid black !important;
  color: #181717 !important;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
  text-align: left;
  max-width: 386px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.side-menu-content .logout-button:hover {
  background: #e9484a;
  color: white !important;
  border-color: white !important;
}

/* dashboardsec */
section.header_Sec.all_sections {
  position: sticky;
  background: #000 !important;
  width: 100% !important;
  max-width: 100%;
  top: 0;
  padding: 20px 0px;
  z-index: 9;
}
section.header_Sec.all_sections .header_main {
  max-width: 90%;
  margin: 0 auto;
}
button.btn_three,
.btn_Twowrapper > button:last-child {
  background: #181717;
  color: #fff;
  min-width: 250px;
  min-height: 70px;
  border-radius: 6px;
  transition: 0.5s all;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
}
button.btn_three:hover,
.btn_Twowrapper > button:last-child:hover {
  background: transparent;
  color: #111;
}
.btn_Twowrapper > button:first-child {
  color: #fff;
  min-width: 250px;
  min-height: 70px;
  border-radius: 6px;
  transition: 0.5s all;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
}
.btn_Twowrapper > button:first-child:hover {
  background: transparent;
  color: #111;
}
.side-menu-content button.btn_three {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.btn_Twowrapper {
  position: absolute;
  right: 30px;
  left: 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.btn_Twowrapper {
  position: absolute;
  right: 30px;
  left: 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 20px;
}
.btn_Twowrapper {
  position: absolute;
  right: 30px;
  left: 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 20px;
}
.btn_Twowrapper > button:first-child {
  color: #fff;
  min-width: 250px;
  min-height: 70px;
  border-radius: 6px;
  transition: 0.5s all;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
  color: #111;
  background: transparent;
}
.btn_Twowrapper {
  position: absolute;
  right: 30px;
  left: 30px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 20px;
  bottom: 30px;
  background: #fff;
}
/* Responsive */
@media (max-width: 767px) {
  .Header_btns button {
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
  }

  .Header_btns button:first-child {
    padding: 0px 9px;
    min-height: 42px;
    margin: 0px;
  }
  .side-menu-content span.searchbycodestep {
    font-size: 14px;
    line-height: 21px;
  }
  .Header_btns button:last-child {
    max-width: 68px;
    min-height: 32px;
  }

  .Header_btns {
    gap: 10px;
  }

  .Web_logo {
    max-width: 80px;
    width: 100%;
  }

  .Web_logo img {
    width: 100%;
  }

  .user-icon {
    width: 32px;
    height: 32px;
  }
  span.btn_mobis {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background: #fff;
    padding: 10px;
    box-shadow: 0px -3px 4px 0px #0000000d;
  }
  .side-menu-content button.btn_three,
  .btn_Twowrapper button {
    position: unset;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 18.75px !important;
    min-height: 48px !important;
    min-width: 100px !important;
    border-radius: 8px !important;
    max-width: 100% !important;
    width: 100% !important;
  }
  span.btn_mobis .btn_Twowrapper {
    position: unset;
  }
  button.btn_three:hover, .btn_Twowrapper > button:last-child:hover {
    background: #181717;
    color: #fff;
}
}

@media (max-width: 380px) {
  .Header_btns button {
    font-size: 10px;
  }

  .Web_logo {
    max-width: 50px;
    width: 100%;
  }
}
