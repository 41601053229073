.purchases-list {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.purchases-list h2 {
    font-size: 24px;
    color: #333333;
}

.search-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 10px;
    border: 1px solid #EDEDED;
    border-radius: 5px 0 0 5px;
}

.search-bar button {
    padding: 10px;
    background-color: #DAA520;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.search-bar button i {
    color: white;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th, table td {
    padding: 15px;
    border: 1px solid #EDEDED;
    text-align: left;
}

.player {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #EDEDED;
    margin-top: 20px;
}

.player p {
    font-size: 16px;
    color: #333333;
    flex: 1;
}

.player button {
    padding: 10px 20px;
    background-color: #DAA520;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.player input[type="range"] {
    margin-left: 10px;
    width: 100px;
}
.no-purchases {
    text-align: center;
    padding: 50px;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.no-purchases-content {
    margin-top: 20px;
}

.no-purchases h2 {
    font-size: 24px;
    color: #333333;
}

.no-purchases p {
    font-size: 18px;
    color: #666666;
    margin: 10px 0;
}

.explore-button {
    background-color: #DAA520;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.explore-button:hover {
    background-color: #C8961D;
}
