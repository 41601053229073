.top_barDashboard {
    padding: 15px 0px;
}
.left_nav .MuiListItemIcon-root {
    min-width: fit-content;
    padding-right: 10px;
}
.left_nav nav {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.left_nav nav a{
    cursor: pointer;
    transition: .5s all;
    background: transparent;
    border-radius: 6px;
    color: #fff;
}
.left_nav nav a:hover svg {
    fill: #111;
}
/* .left_nav nav a:hover{
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    border-radius: 6px;
    color: #111;
    } */


    .highlightsidebar {
        background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    border-radius: 6px;
    color: #111;
    }
    
.left_nav .css-10hburv-MuiTypography-root {
    display: flex;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    gap: 6px;
    white-space: pre;
}


/* .left_nav nav>a:first-child {
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    border-radius: 6px;
    color: #111;
} */

.highlightsidebar{
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    border-radius: 6px;
    color: #111; 
}

.left_nav nav a:hover {
    background: linear-gradient(rgb(244, 192, 92) 0%, rgb(216, 161, 54) 100%) !important;
    border-radius: 6px;
    color: rgb(17, 17, 17) !important;
}
.left_nav nav a:hover img{
filter: invert(1);
}
/* .Dashbaordimg.content */
.home_dashboard {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.custom-form .add_option{
    margin: 15px 0px 0px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
    justify-content: space-between;
    background: #f9f9f9;
    margin-top: 0px;
    /* height: 100vh; */
}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
    padding-top: 0px !important;
}
.Recent_updates {
    height: fit-content;
}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
    padding-left: 20px;
}
.MuiGrid-root.MuiGrid-container .Dashboard_rightContent {
    padding-top: 24px !important;
    padding-left: 0px;
}
.Dashboard_rightContent .MuiPaper-root.MuiPaper-elevation {
    background: transparent;
    box-shadow: none;
}
.Dashbaordimg_content {
    display: flex;
    align-items: center;
    gap: 15px;
}
.Dashbaordimg_content h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.28px;
    text-align: left;
    margin: 0px 0px 5px;
}
.Dashbaordimg_content h4 b {
    font-size: 20px;
}
.users_dashboard {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    height: fit-content;
}
.dashboard_bg{
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    border: 1px solid var(--Color-black-05, #EFEFEF);
}
.Dashbaordimg_content h5 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0px;
}
.Dashbaordimg_content p {
    font-family: Poppins;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    padding: 0px;
    margin: 0px;
}
.Dashbaordimg_content .Music_update p {
    color: #769EFF;
}
.Dashbaordimg_content .Content_update p {
    color: #FFB894; 
}
.Dashbaordimg_content .Music_update {
    border-right: 1px solid #D2D2D2;
    padding-right: 15px;
}





/* .Statement_page */
.Statement_page {
    padding-right: 20px;
}
.Statement_page_firstcontent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.Statement_page_firstcontent h4 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
}
.statement_search {position: relative;}

.statement_search input::placeholder {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color:#9D9C9C;
}
.statement_search input:focus-visible{
    outline:0px ;
}
.statement_search input{
    max-width: 360px;
    min-height: 54px;
    padding: 15px 16px 15px 16px;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #DFDFDF; 
    padding-left:35px;
     font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.statement_search svg {
    position: absolute;
    left: 10px;
    top:50%;
    transform:translateY(-50%);
    width:20px;
    height:20px;
    fill:#9D9C9C;
}

.Statement_dashboard {
    border: 1px solid #EFEFEF;
    min-height: 105px;
    padding: 15px 16px 15px 16px;
    border-radius: 8px;
    background: #fff;
    text-align: left;
    min-width: 200px;
    max-width: 244px;
}
.Statement_dashboard.bg_blue{
    border: 1px solid #769EFF;
    background: #E7EEFF;

}
.Statement_dashboard h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}
.Statement_dashboard h3 {
    font-family: Roboto;
    font-size: 33px;
    font-weight: 500;
    line-height: 38.67px;
    letter-spacing: 0.800000011920929px;
    text-align: left;
}
.Statemenet_table tr>th:first-child{
    border-radius: 9px 0px 0px 0px;
}
.Statemenet_table tr>th:last-child{
    border-radius: 0px 0px 0px 9px;
}
.Statemenet_table tr>th {
    background: #F3F3F3;
    border: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.800000011920929px;
    color: #707070;
}
.Statemenet_table tr, .Statemenet_table tr td {
    padding: 15px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #444343;    
}
.Statemenet_table {
    background: #fff;
}

.Statemenet_table.mt-3 tr>th {
    background: #F3F3F3;
    border: 0px;
    border-radius: 5px 5px 0px 0px;
    padding: 15px 10px;
}

.Statemenet_table tr td.expired {
    height: 34px;
    width: 100%;
    max-width: fit-content;
    margin: 8px auto 0px;
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    background: #FFF1F1;
    color: #FF1111;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* upload_music */

.popupbg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: var(--Color-black-100, #181717f0);
    display: none;
    align-items: center;
    justify-content: center;
    transition: .5s all;
}
.Statement_page.active .popupbg {
    display: flex;
}
.Popu_div {
    background: #FFFFFF;
    width: 100%;
    min-height: 601px;
    max-width: 818px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.Statement_page.active .Popu_div button, .Statement_page.active .Popu_div button:hover {
    width: 100%;
    max-width: 200px;
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    color: #111;
    border: 0px !important;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.Popu_div h3 {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
    margin-top: 10px;
}
.Popu_div img.close_btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
}
.content_info form>div:first-child label {
    padding: 8px 0px;
}



/* responsive */

@media (max-width:1600px){
    .left_nav .css-10hburv-MuiTypography-root {
        font-size: 14px;            
        line-height: 21px;  
    }   
}

@media (min-width:1400px){  
    .left_nav nav {
        width: 100%;
        max-width: 19.6%;
    }

}
@media (max-width:1400px){
    .left_nav nav {
        padding: 10px;
        gap: 10px;
    }
    .left_nav nav {
        width: 100%;
        max-width: 19.3%;
    }
  
    .left_nav nav a {
        padding: 10px;
    }
}
@media (max-width:1200px){
    .home_dashboard {
        grid-template-columns: 1fr;
    }
    .left_nav nav {
        width: 100%;
        max-width: 26%;
    }
    .Recent_musicVideo {
        height: 100%;
        overflow-y: unset;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9\.35.Dashboard_rightContent {
        max-width: 70%;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2\.5 {
        max-width: 30%;
        flex-basis: 26%;
        background: #2f3032;
        height: 100vh;
    }

    .users_dashboard {
        gap: 10px;
        flex-direction: column;
        text-align: left;
    }
    .Music_videoUpload .Uploaed_card {
        flex-direction: column;
    }
    .Statemenet_table.mt-3 {
        overflow-x: auto;
    }
    .Statemenet_table.mt-3 table.table {
        min-width: 1200px;
    }
}

@media (max-width:991px){
    .Popu_div {
        background: #FFFFFF;
        width: 100%;
        min-height: 270px;
        max-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 16px 16px 0px 0px;
    }
 
}
@media (min-width:768px){
    header.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4.MuiAppBar-root.MuiAppBar-colorPrimary.MuiAppBar-positionStatic.top_barDashboard.css-hip9hq-MuiPaper-root-MuiAppBar-root {
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .desktoponly{
        display: block;
    }
    .custom-form .note_SecWrapper.mobile_only span.close_icon {
        display: none;
    }
    .left_nav nav {
        position: fixed;
        top: 93px;
        overflow-y: auto;
    }
    .left_nav nav::-webkit-scrollbar {
        width: 5px;
      }
      
      /* Track */
      .left_nav nav::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      .left_nav nav::-webkit-scrollbar-thumb {
        background: #888; 
      }
      
      /* Handle on hover */
      .left_nav nav::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
}

@media (max-width:767px){
    .home_dashboard>div:first-child {
        order: 2;
    }
    .desktoponly{
        display: none;
    }
    .custom-form.content_info.active .note_SecWrapper.mobile_only, .custom-form.active .note_SecWrapper.mobile_only {
        display: none;
    }

    .Dashboard_rightContent .custom-form>div:nth-child(2) {
        padding: 0px;
    }
    .note_secwrap {
        position: relative;
    }
    .col-md-6.custom-form.active .note_SecWrapper.mobile_only {
        display: none;
    }
    .note_secwrap span.close_icon {
        position: absolute;
        top: -10px;
        right: 0px;
        cursor: pointer;
    }
    .Statemenet_table.mt-3 tr>th {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.800000011920929px;
    }
    .Popu_div h3 {
        font-size: 20px;
        line-height: 23px;
    }
    .Popu_div>img:nth-child(2) {
        width: 100%;
        max-width: 153px;
    }
    .Statement_page {
        padding-left: 24px;
    }
    .statement_search svg {
        position: relative;
        transform: unset;
        top: -5px;
        left: unset;
    }
    .Statement_page_firstcontent h4 {
        font-size: 20px;
        line-height: 30px;
    }
    .Statemenet_table tr, .Statemenet_table tr td {
        padding: 10px;
        font-size: 12px;
        line-height: 18px;
    }
    .Statement_dashboard h4 {
        font-size: 12px;
        line-height: 18px;
    }
    .Statement_dashboard h3 {
        font-size: 20px;
        line-height: 23.44px;
        letter-spacing: 0.800000011920929px;
    }
    .statement_search.active span.close_icon.d-none {
        display: block !important;
    }
    .statement_search.active span.search_icons {
        display: none;
    }
    .Statement_dashboard {
        min-height: 61px;
        padding: 5px 7px 4px;
        border-radius: 8px;
        min-width: 90px;
        max-width: 90px;
    }
    .statement_search input {
        max-width: 320px;
        min-height: 49px;
        padding: 5px;
        padding-left: 15px;
        position: absolute;
        right: 30px;
        top: -15px;
        /* opacity: 0; */
        transition: .3s all;
    }
    .statement_search.active input {
        opacity: 1;
    }
    .statement_search.active{
        background-color: transparent;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2\.5 {
        height: unset;
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
        padding: 0px 0px 0px 15px;
    }
    .highlightsidebar{
        background: transparent;
    }
    .left_nav nav a img {
        filter: brightness(0.5);
    }
    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-zow5z4-MuiGrid-root {
        background: #fff;
    }
    .left_nav nav {
        width: 100%;
        max-width: 100%;
    }
    .home_dashboard {
        margin-right: 0px;
        padding: 0px 15px 70px;
        gap: 15px;
    }
    .Dashbaordimg_content img {
        width: 47px;
        height: 47px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-9\.35.Dashboard_rightContent {
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 0px;
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-2\.5 {
        max-width: 100%;
        flex-basis: 100%;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        padding: 0px;
    }
    .left_nav nav a:hover{
        background: transparent !important;
        color: unset !important;
    }
    .left_nav nav {
        padding: 15px 10px 5px;
        gap: 10px;
        background: #fff !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        color: #111 !important;
        flex-direction: unset;
        justify-content: space-around;
        box-shadow: 0px -4px 4px 0px #C8C8C840;
    }
    .left_nav nav a{
        padding: 0px;
        display: flex;
        flex-direction: column;
        /* width: fit-content; */
        background: transparent !important;
        color: #9D9C9C !important;
    }
    .chart-container canvas {
        /* width: 100% !important; */
    /* max-width: 100%; */
    margin: 0 auto;
    width: 100% !IMPORTANT;
    }
    .left_nav nav>a:first-child {
        color: #111;
        background: transparent !important;
    }

    .Dashbaordimg_content h4 b {
        font-size: 13px;
    }
    .Dashbaordimg_content h4 {
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        line-height: 13.3px;
    }
    .Dashbaordimg_content h5 {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }
    .Dashbaordimg_content p {
        font-size: 20px;
    }
    .left_nav nav li svg {
        color: #9D9C9C !important;
    }
    .left_nav .MuiListItemIcon-root {
        padding-right: 0px;
    }
    .left_nav .css-10hburv-MuiTypography-root {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
    }
    .left_nav nav>a:first-child span {
        background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
    }
    .left_nav nav>a:first-child svg {
        fill: #F4C05C;
    }
    .left_nav nav>a svg {
        fill: #9D9C9C;
    }
    .dashboard_bg {
        padding: 15px;
    }
}
/* Responsive Styles */
@media (max-width: 768px) {
    .desktoponly {
      display: none;
    }
  
    .statement_page_firstcontent {
      flex-direction: column;
      align-items: flex-start;
    }
}

  .Dashboard_rightContent .custom-form {
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px #00000026;
    border-radius: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px;
  }
 .Dashboard_rightContent .custom-form label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    padding: 0px 0px 10px;
}
.Dashboard_rightContent form.custom-form input.form-control,   .Dashboard_rightContent .form-select {
    border: 1px solid #000000 !important;
    min-height: 54px !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    text-align: left !important;
    border-radius: 8px !important;
}
.Dashboard_rightContent .form-control:focus,  .Dashboard_rightContent .form-select:focus,   .Dashboard_rightContent button.btn:focus {
    box-shadow: none;
    border: 0px ;
}
.Dashboard_rightContent button.btn {
    background: #181717;
    border-color: #181717;
    min-height: 50px;
    width: 100%;
    /* max-width: 366px; */
    margin: 0 auto;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.09px;
    border-radius: 8px;
    transition: .5s all;
    border: 1px solid !important;
}
.custom-form .note_SecWrapper.mobile_only {
    padding-bottom: 0px;
}
.Dashboard_rightContent button.btn:hover {
    background: transparent;
    color: #181717;
}

.Dashboard_rightContent .step_formwrapper {
    text-align: left;
}



.active-link {
    background-color: red;
  }
  



  @media (max-width: 425px) {

.Statement_page_firstcontent{
    flex-direction: column;
    display: flex;
    text-align: start;
    align-items: start;
}
  .statement_search span{
  
    padding-left: 21rem;

   
   
  } 
     .Statement_page_firstcontent h4{

        margin-bottom: 21px;

  }

  }