.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #071224;
  padding: 18px;
  text-decoration: none;
  z-index: 1000;
}


.header_main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left_header {
  display: flex;
  flex-direction: row;
}

.header_name {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  text-decoration: none;
}

.header_right {
  display: flex;
  flex-direction: row;
}

.user_icon {
  margin-right: 36px;
}

.settings_icon {
  margin-right: 42px;
}

.hamburger{
  margin-top: auto;
  margin-bottom: auto;
  color: white;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .header {
    padding: 10px;
  }

  .header_name {
    font-size: 18px;
    line-height: 27px;
  }

  .user_icon {
    margin-right: 0px;
    height: 30px;
    width: 30px;
  }

  .settings_icon {
    margin-right: 12px;
    height: 20px;
    width: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }
}