
form.profile_setting h1.text-start {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
    text-align: left;
    margin: 10px 0px 0px;
}
form.profile_setting {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 12px 0px #00000026;
    padding: 20px;
}
form.profile_setting button {
    max-width: 232px;
    margin-left: 0px;
}
.profile_Div img {
    object-fit: contain;
}
.profile_Div {
    display: flex;
    text-align: left;
    gap: 20px;
}
.profile_Div h5 {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
    color: #181717;
}
.profile_Div p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: #707070;
}

.user-component .purchases-list form.profile_setting  button {
    background: #181717;
    color: #fff;
    min-width: 232px;
    min-height: 45px;
    border-radius: 6px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.09px;
    transition: .5s all;
}
.user-component .purchases-list form.profile_setting button:hover {
    background: transparent;
    color: #111;
}
.user-component .purchases-list input:focus {
    box-shadow: none;
}
.user-component .purchases-list .iput_search input {
    min-height: 45px;
}
.user-component .purchases-list input, .user-component .purchases-list select {
    border-color: #181717;
    min-height: 54px;
}
.user-component .purchases-list label.form-label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    display: flex;
}

span.btn_udate {
    display: flex;
}
@media (max-width:767px){
    .Top_header .iput_search input {
        display: none;
    }
    .profile_Div img {
        object-fit: contain;
        width: 75px;
        height: 75px;
    }
    .profile_Div h5 {
        font-size: 16px;
        line-height: 18.75px;
        margin-bottom: 5px;
    }
    .profile_Div p {
        font-size: 10px;
        line-height: 11.72px;
        margin-bottom: 10px;
    }
    .user-component .purchases-list form.profile_setting  button {
        min-width: 104px;
        min-height: 32px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 16.41px;
    }
    .user-component .purchases-list form.profile_setting  span.btn_udate button {
        min-height: 42px !important;
        border-radius: 4px !important;
        font-size: 14px !important;
        line-height: 16.41px !important;
    }
    .user-component .purchases-list input, .user-component .purchases-list select {
        border-color: #181717;
        min-height: 42px;
        font-size: 12px;
    }
    .user-component .purchases-list label.form-label {
        font-size: 12px;
        line-height: 18px;
    }
    .profile_setting .row {
        gap: 13px;
    }
    form.profile_setting h1.text-start {
        font-size: 20px;
        line-height: 23.44px;
    }
}