.purchases-list {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.purchases-list h2 {
    font-size: 24px;
    color: #333333;
}

.search-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.search-bar input {
    padding: 10px;
    border: 1px solid #EDEDED;
    border-radius: 5px 0 0 5px;
}

.search-bar button {
    padding: 10px;
    background-color: #DAA520;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.search-bar button i {
    color: white;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th, table td {
    padding: 15px;
    border: 1px solid #EDEDED;
    text-align: left;
}

.player {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #EDEDED;
    margin-top: 20px;
}

.player p {
    font-size: 16px;
    color: #333333;
    flex: 1;
}

/* .player button {
    padding: 10px 20px;
    background-color: #DAA520;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
} */
.left_contentfooter button {
    background: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
}   
.left_contentfooter span.play_btn img {
    filter: invert(1);
    cursor: pointer;
}
.player input[type="range"] {
    margin-left: 10px;
    width: 100px;
}
.no-purchases {
    text-align: center;
    padding: 50px;
    border: 1px solid #EDEDED;
    background-color: #FFFFFF;
    border-radius: 8px;
}

.no-purchases-content {
    margin-top: 20px;
}

.no-purchases h2 {
    font-size: 24px;
    color: #333333;
}

.no-purchases p {
    font-size: 18px;
    color: #666666;
    margin: 10px 0;
}

.explore-button {
    background-color: #DAA520;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}
.user-component .purchases-list table.purchase_table {
    padding: 0px 20px;
    display: table;
    width: 97%;
    margin: 20px auto 0px;
}

.explore-button:hover {
    background-color: #C8961D;
}
table.purchase_table tr, table.purchase_table td, table.purchase_table th {
    border: 0px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}
table.purchase_table tr {
    border-bottom: 1px solid #EFEFEF !important;
    height: 75px;
}
table.purchase_table thead tr{
    border: 0px !important;
    height: fit-content !important;
}
table.purchase_table thead>tr {
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #666;
    color: #fff;
}
table.purchase_table thead{
    background: #181717;
}
.player {
    position: sticky;
    background: #000;
    left: 0;
    bottom: -5px;
    right: 0px;
    height: 94px;
    padding: 20px 10px !important;
    z-index: 1;
}
.Top_header {
    border: 0px;
}
.play_botto {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 90%;
    margin: 0px auto;
    align-items: center;
}
.left_contentfooter {
    display: flex;
    align-items: center;
    gap: 20px;
}
.left_contentfooter h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    color: lemonchiffon;
    margin: 0px;
    padding-left: 20px;
}
.left_contentfooter h3 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    display: block;
    margin: 5px 0px 0px;
}
.right_footer {
    display: flex;
    align-items: center;
    gap: 20px;
}
.right_footer .icons_right {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: 20px;
}
.right_footer .icons_right img{
    cursor: pointer;
}

.player .left_contentfooter button, 
.player button {
    background-color: transparent !important;
    margin: 0px;
}
button.btn_One {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    background: transparent;
    border: 1px solid white !important;
    border-radius: 30px !important;
    transition: .5s all;
    padding: 10px 30px !important;
}
button.btn_One:hover {
    background: #fff !important;
    color: #111 !important;
}
button.btn_Two {
    border: 1px solid transparent !important;
    border-radius: 30px !important;
    padding: 10px 30px !important;
    transition: .5s all;

}
.user-component .purchases-list table.purchase_table img {
    filter: invert(1);
}
/* Base button styles */
 button.btn_Two {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    border: 1px solid;
    border-radius: 30px;
    padding: 10px 30px;
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent; /* Fallback for non-WebKit browsers */
    background-clip: text;
    transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    position: relative;
    z-index: 1;
    overflow: hidden;
}


/* Adding gradient border */
button.btn_Two::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 30px;
    padding: 2px; /* Adjust as needed */
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude; /* Adjust for better browser support if needed */
    z-index: -1;
}
/* Hover effect */
button.btn_Two:hover {
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: #111;
    background: linear-gradient(180deg, #F4C05C 0%, #D8A136 100%);
}



button.btn_Two:hover span {
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    color: white;
}
@media (max-width:767px){
    .right_footer, .left_contentfooter {
        gap: 10px;
    }
    .player {
        bottom: 0px;
        position: fixed;
    }
    .right_footer button.btn_One {
        padding: 0px !important;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
    }
    .right_footer button.btn_Two {
        padding: 0px !important;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;
    }
    .right_footer .icons_right {
        gap: 10px;
        margin-right: 5px;
    }
    .play_botto {
        width: 100%;
        max-width: 100%;
        gap: 5px;
    }
    .left_contentfooter h3 {
        padding-left: 0px;
        font-size: 11px;
        line-height: 15px;
    }
    .player {
        padding: 20px !important;
    }
    .left_contentfooter h3 span {
        font-size: 10px;
        line-height: 15px;
    }
    .right_footer .icons_right img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
    .purchase_list ul {
        padding: 20px 0px 0px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .purchase_list ul li {
        list-style: none;
        display: flex;
        gap: 10px;
        border-bottom: 1px solid #DFDFDF;
        padding-bottom: 15px;
        justify-content: space-between;
        align-items: center;
    }
    .purchase_list ul li span.vide_content {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
    }
    .purchase_list ul li h3 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #181717;
        margin: 0px;
        padding: 0px;
    }
    
    .purchase_list ul li p {
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #707070;
        margin: 0px;
    }
    .purchase_list ul li span.cart_icons {
        display: flex;
        justify-content: end;
        align-items: end;
        gap: 10px;
    }
    
    .purchase_list ul li span.cart_icons>img:first-child {
        filter: invert(1);
    }
    table.purchase_table tr>td:nth-child(6), table.purchase_table tr>td:last-child{
        text-align: right;

    }
    table.purchase_table td{
        padding: 0px;
        margin: 0 auto;
    }
    .purchase_list ul li span.cart_icons img {
        width: 14px;
        height: 14px;
    }
    table.purchase_table p.music-title {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        text-decoration: underline;
    }
    .music-artist {
        margin: 0;
        color: #777;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
    }
    span.btn_udate {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        max-width: 100%;
        margin: 0px auto;
        box-shadow: 0px 0px 4px 0px #00000040;
        background: white;
        padding: 10px 15px;
    }
    span.btn_udate button.form_btn {
        width: 100%;
        max-width: 100%;
        margin: 0px !important;
        padding: 0px;
    }
    table.purchase_table td button.btn_Two {
        height: 30px;
        padding: 0px !important;
        width: 30px;
        position: relative;
    }
    table.purchase_table td button.btn_Two img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
