/* OTPInput.css */
.otp-container {
  display: flex;
  justify-content: left;
}

.otp-input {
  width: 50px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  text-align: center;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  transition: border 0.3s ease;
}

.otp-input-focus {
  border-color: #000;
}

@media (max-width: 767px) {
  .otp-container {
    display: flex;
    justify-content: center;
  }
  .otp-input{
    margin-right: 20px;
  }
}