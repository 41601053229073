/* Add these styles to your CSS file */
.license-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 600;
  text-align: left;
}

.form-group input,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.form-group.checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

textarea {
  resize: vertical;
}

.form-group.checkbox input[type="checkbox"] {
  margin-right: 10px;
  margin-left: 0;
}

.form-group.checkbox label {
  margin-bottom: 0;
  font-weight: normal;
}