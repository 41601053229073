.custom-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns the content to the left */
  width: 100%; /* Ensures full width */
}

.custom-input-label {
  flex-grow: 1; /* Ensures label takes available space */
  padding-right: 10px;
}

.custom-pencil-icon {
  cursor: pointer;
  margin-left: auto; /* Aligns the icon to the extreme right */
  height: 24px;
  width: 24px;
}

.custom-input-field {
  flex-grow: 1; /* Ensures input field takes available space */
  margin-right: 10px; /* Adds space between input field and button */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.custom-save-btn {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 4px 8px; /* Make button even smaller */
  font-size: 12px;    /* Smaller font size */
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0; /* Ensures it's right next to the input field */
  height: 32px; /* To match the input field height */
}

.custom-save-btn:hover {
  background-color: #333;
}

.btn-custom {
  margin-top: 20px;
}