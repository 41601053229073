.Music_videoUpload {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px 0px;
}

.Music_videoUpload .Uploaed_card {
    box-shadow: 0px 2px 14px 0px #00000008;
    border: 2px solid var(--white, #FFFFFF);
    border-radius: 10px;
    padding: 40px 20px;
    display: flex;
    align-items: flex-start;
    text-align: left;
    gap: 20px;
}

.bg_blue{
    background: #E7EEFF;
}
.bg_lightPink {
    background: #FFEFE7;
}
.Music_videoUpload .Uploaded_content h5 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
}
.Music_videoUpload .Uploaded_content p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 10px 0px 15px;
    max-width: 210px;
}
.Music_videoUpload .Uploaded_content button {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    background: #181717;
    color: #fff;
    height: 48px;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    border: 1px solid #181717;
    transition: .5s all;
}
.Uploaded_content {
    width: 100%;
}
.Music_videoUpload .Uploaded_content button:hover {
    background: transparent;
    color: #111;
}

@media (max-width:767px){
    .Music_videoUpload .Uploaed_card {
        padding: 20px 10px;
    }
    .Music_videoUpload {
        grid-gap: 10px;
        margin: 15px 0px;
    }
    .Music_videoUpload .Uploaded_content h5 {
        font-size: 13px;
        line-height: 21px;
    }
    .Music_videoUpload .Uploaded_content p {
        font-size: 10px;    
        line-height: 15px;
        margin: 5px 0px 10px;
    }
    .Music_videoUpload .Uploaded_content button {
        font-size: 14px;
        line-height: 21px;
        background: #181717;
        height: 41px;
    }
    .Music_videoUpload .Uploaed_card img {
        width: 28px;
    }
}