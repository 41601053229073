.Container {
    /* width: 100%; */
    max-width: 1280px;
    margin: 0px auto;
    padding: 0px 15px;
}
.bg_trustSec{
    background: #FAF9F9;
}
.Logo_sec{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.trust_content{
    display: flex;
    gap: 70px;
    justify-content: space-between;
    align-items: center;
}
.trust_content h3{
    font-family: poppins;
    color: #181717;
    font-size: 32px;
    line-height: 48px;
    margin: 0px;
}

/* responsive */

@media (max-width:1200px){
    .trust_content {
        flex-wrap: wrap;
    }
}
@media (max-width:767px){
    section.bg_trustSec .Container {
        padding: 30px 0px 40px !important;
    }
    .Logo_sec {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        border: 1px solid #dfdfdf;
        border-right: 0px;
        border-left: 0px;
    }
    .trust_content {
         gap: 30px;
        flex-direction: column;
    }
    .trust_content h3 {
        color: #181717;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        max-width: 330px;
    }
    .Logo_sec img {
        width: 100%;
    }
}